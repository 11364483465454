import axios from "axios";
import Cookies from "js-cookie";
import {map, omit, reduce, cloneDeep} from "lodash-es";
import uuid from "@songfinch/shared/helpers/uuid";
import sha256 from "@songfinch/shared/helpers/sha256";

import app from "@songfinch/customer/app.js";
import router from "@songfinch/customer/router";
import store from "@songfinch/customer/store";

import gtm_song_data_helper, {
    initGtmData,
} from "../helpers/gtm_song_data_helper";
import useInstantProductBuilder from "@songfinch/customer/composables/useInstantProductBuilder";
import {getSessionUser} from "@songfinch/customer/store_v2/storeTempUser";
import {flipperValues} from "@songfinch/shared/plugins/flipper";

const {vitamixCollectionTitle} = useInstantProductBuilder();

const SONG_BUILDER_QUIZ_NAME = "purchase_last";
const SONG_BUILDER_QUIZ_NUMBER = "3";

const NON_ADDON_PRODUCTS = ["song", "gift-card"];

function mapBackendProductToEventProductModel(p) {
    // Note we are remapping Product.title => TrackedProduct.name for using the "human readable name when forwarding
    // Product.name => TrackedProduct.code
    return {
        id: p.sku,
        product_id: p.sku,
        content_id: p.sku,
        content_type: "product",
        name: p.title,
        code: p.name,
        quantity: p.quantity,
        cta_clicked: p.cta_clicked,
        brand: "songfinch",
        category: p.product_type,
        price: (p.cartPrice ?? p.price) / 100,
        currency: "USD",
        product_type: p.product_type,
        add_on: !NON_ADDON_PRODUCTS.includes(p.product_type),
        image_url: app.config.globalProperties.$cld.url(p.photo) || "",
        variant_name: p.extra_info?.variant_name,
        instant_product: p.extra_info?.instant_product
    };
}

function getProducts() {
    const cart = store.state.cart.cart;

    //Filter products that has price
    //Submitted song will be free product we need to exclude it
    const products = cart.products
        .filter((p) => +p.price)
        .map(mapBackendProductToEventProductModel);

    return products;
}

function dateTimeInSeconds() {
    return Math.round(Date.now() / 1000);
}

function mapAndEnrichSongbuilderDataForSegment(songBuilder) {
    const gtmSongData = cloneDeep(songBuilder.gtmSongData);
    const segmentData = {};
    const keysToRemap = [
        "gender", // song_artist_gender
        "genre", // song_genre
        "gifter_email", // song_customer_email
        "gtm_m_h_questions", // map to dict and rename to song_must_have_questions
        "pronunciations", // boolean map and rename to song_pronunciations
        "for_someone_else", // boolean map and rename to song_for_someone_else
        "for_occasion", // boolean map and rename to song_for_occasion
        "mention_occasion", // boolean map and rename to song_mention_occasion
        "mention_recipient", // boolean map and rename to song_mention_recipient
        "moods", // song_moods
        "occasion", // song_occasion
        "recipient", // rename to song_recipient_name
        "recommended_artist_id", // prefix w/ song
        "recommended_artist_name", // prefix w/ song
        "recommended_artists", // prefix w/ song
        "relationship", // prefix w/ song
        "submitted_artists", // prefix w/ song
        "story_details", // map to dict and then rename to song_story_details
        "story_additional_details", // map to dict and then rename to song_story_additional_details
        "tempo", // song_tempo
        "delivery_type", // with or without purchasing an ecard
        "ag_ecard" // e-card product object
    ];
    //Remap Known Keys Fields
    keysToRemap.forEach((fm) => {
        // Skipp remapping if we dont have the field in GTM data
        if (gtmSongData[fm] === undefined) return;
        // Business logic to map fields to updated values
        switch (fm) {
        case "gender":
            segmentData.song_vocals = gtmSongData.gender;
            break;
        case "genre":
            segmentData.song_genre = gtmSongData.genre;
            break;
        case "gifter_email":
            segmentData.song_customer_email = gtmSongData.gifter_email;
            break;
        case "gtm_m_h_questions":
            segmentData.song_must_have_questions = reduce(
                songBuilder.songData.must_have_questions,
                (acc, el, idx) => {
                    if (el.answer) {
                        acc[`must_have_${idx}`] = {
                            title: el.answer,
                            answer: el.description,
                        };
                    }
                    return acc;
                },
                {}
            );
            break;
        case "mention_occasion":
            segmentData.song_mention_occasion =
                    gtmSongData.mention_occasion.toLowerCase() === "yes";
            break;
        case "mention_recipient":
            segmentData.song_mention_recipient =
                    gtmSongData.mention_recipient.toLowerCase() === "yes";
            break;
        case "for_someone_else":
            segmentData.song_for_someone_else =
                    gtmSongData.for_someone_else.toLowerCase() === "yes";
            break;
        case "for_occasion":
            segmentData.song_for_occasion =
                    gtmSongData.for_occasion.toLowerCase() === "yes";
            break;
        case "moods":
            segmentData.song_moods = gtmSongData.moods;
            break;
        case "occasion":
            segmentData.song_occasion = gtmSongData.occasion;
            break;
        case "recipient":
            segmentData.song_recipient = gtmSongData.recipient;
            break;
        case "recommended_artist_id":
            segmentData.song_recommended_artist_id =
                    gtmSongData.recommended_artist_id;
            break;
        case "recommended_artist_name":
            segmentData.song_recommended_artist_name =
                    gtmSongData.recommended_artist_name;
            break;
        case "recommended_artists":
            segmentData.song_recommended_artists =
                    gtmSongData.recommended_artists;
            break;
        case "relationship":
            segmentData.song_relationship = gtmSongData.relationship;
            break;
        case "submitted_artists":
            segmentData.song_submitted_artists =
                    gtmSongData.submitted_artists;
            break;
        case "story_details":
            //Ensure we have the response before remapping
            if (!songBuilder.songData.questions?.[0]) break;
            segmentData.song_story_details = {
                question: songBuilder.songData.questions[0].question,
                answer: songBuilder.songData.questions[0].answer,
            };
            break;
        case "story_additional_details":
            //Ensure we have the response before remapping
            if (!songBuilder.songData.questions?.[1]) break;
            segmentData.song_story_additional_details = {
                question: songBuilder.songData.questions[1].question,
                answer: songBuilder.songData.questions[1].answer,
            };
            break;
        case "tempo":
            segmentData.song_tempo = gtmSongData.tempo;
            break;
        case "pronunciations":
            segmentData.song_pronunciations = gtmSongData.pronunciations;
            break;
        case "delivery_type":
            segmentData.deliveryType = gtmSongData.deliveryType;
            break;
        case "ag_ecard":
            segmentData.ag_ecard = gtmSongData.ag_ecard;
            break;
        default:
            console.warn(`Missing case for field mapping: ${fm}`);
            break;
        }
    });
    //Copy remaining keys into segmentData
    Object.keys(gtmSongData).forEach((k) => {
        // Ignore keys we've already mapped
        if (keysToRemap.indexOf(k) >= 0) return;
        segmentData[k] = gtmSongData[k];
    });
    return segmentData;
}

async function getCartStage() {
    const cart = store.state.cart.cart;
    const song = cart.hasOriginalSong;

    //If we have song in cart
    if (song) {
        //If song for submission
        if (song.parent_story_id) {
            return "Story Submission";
        } else {
            //else New Song Purchase
            return "Song Purchase";
        }
    }

    //If no song in cart find first addon that we buying song for
    const firstAddonParentStoryId = cart.products.find(
        (p) => p.parent_story_id
    )?.parent_story_id;
    if (firstAddonParentStoryId) {
        try {
            //Geting song status of first addon
            const res = await axios.get(
                "/stories/get_status_by_id/" + firstAddonParentStoryId,
                {
                    cache: {
                        ignoreCache: false,
                        maxAge: 60000, // 1 min cache
                    },
                }
            );
            const status = res.data.status;
            if (["shipped", "canceled"].includes(status)) {
                return "Song Delivered";
            } else if (["created"].includes(status)) {
                return "Story Submission";
            } else {
                return "Song Production";
            }
        } catch (e) {
            //Not sure if we need to handle error
        }
    }

    return "Merchandise Purchase";
}

function getUserTraitsForSegmentRequest() {
    const user = store.state?.auth?.user;
    let email = user?.email;
    if (!email) {
        //Attempt to get the email from the songbuilder state store captured from the gifter step
        email = store.state?.songBuilder?.songData.gifter_email;
    }
    const first_name = user?.first_name;
    const last_name = user?.last_name;
    const em = sha256(email);
    const fn = sha256(first_name);
    const ln = sha256(last_name);
    const timezone = Intl?.DateTimeFormat().resolvedOptions().timeZone;
    const features = flipperValues;

    let traits = {
        features,
        timezone
    };

    if (email) {
        //Only return account data if we have information about the user's email
        traits = {
            email,
            em,
            first_name,
            fn,
            last_name,
            ln,
            ...traits,
        };
    }


    return traits;
}

function getSessionDataForSegmentRequest() {
    const user = store.state?.auth?.user;
    return {
        gclid: Cookies.get("gclid"),
        fbc: Cookies.get("_fbc"),
        fbp: Cookies.get("_fbp"),
        user_id: getUserIdentifierFromStateStoreOrCookie(user),
    };
}

function getSegmentTrackingContextData(eventId = null, eventName = null) {
    // If we don't have an eventId passed in, generate one with UUID
    const event_id_nonce = eventId ? eventId : uuid().replace("-", "");
    //Construct a tracking event_id based on if we passed on an eventName
    const tracking_event_id = eventName
        ? `${eventName}-${event_id_nonce}`
        : event_id_nonce;
    return {
        traits: getUserTraitsForSegmentRequest(),
        session: getSessionDataForSegmentRequest(),
        event_id: tracking_event_id
    };
}

function getUserIdentifierFromStateStoreOrCookie(user) {
    return (
        user?.external_user_id ||
        Cookies.get("ajs_user_id") ||
        Cookies.get("ajs_anonymous_id") ||
        Cookies.get("_cioanonid")
    );
}

/*PAGEVIEW*/
document.addEventListener("_sf_pageview", (e) => {
    const eventId = "PageView-" + e.detail?.request_id;
    window.dataLayer?.push({ecommerce: null});
    window.dataLayer?.push({
        event: "pageview",
        page: {
            name: router.currentRoute.value.name,
            path: location.pathname,
            pageTitle: document.title,
        },
        fbEventID: eventId,
    });

    window.analytics?.page(router.currentRoute.value.name, {
        affiliate: store.state.cart.cart.affiliate,
        fbEventID: eventId,
        event_id: eventId,
        requestId: e.detail?.request_id,
        timestamp: dateTimeInSeconds(),
        ...getSegmentTrackingContextData(e.detail?.request_id, "PageView"),
    });
});

/* Product Page View - only on build your song page when you start */
/* Trigger: product page load */
document.addEventListener("_sf_viewcontent", async (e) => {
    const gtmData = {
        event: "viewcontent",
        page: {
            path: "/song-builder",
            pageTitle: "Song Builder",
        },
        ecommerce: e.detail.ecommerce,
    };
    window.dataLayer?.push({ecommerce: null});
    window.dataLayer?.push(gtmData);
    window.analytics?.track(
        "Songbuilder Viewed",
        {
            affiliate: store.state.cart.cart.affiliate,
            quiz_name: SONG_BUILDER_QUIZ_NAME,
            quiz_version: SONG_BUILDER_QUIZ_NUMBER,
            timestamp: dateTimeInSeconds()
        },
        getSegmentTrackingContextData()
    );
});

/* Add to cart - all products  */
/* Trigger: Add to cart button */
document.addEventListener("_sf_add_to_cart", async (e) => {
    const product = {...e.detail?.product};
    //excluding products that doesn't have a price
    if (!product.price) return;
    const eventProductModel = mapBackendProductToEventProductModel(product);
    product.price /= 100;
    const page = {
        path: location.pathname,
        pageTitle: document.title,
        name: router.currentRoute.value.name,
    };
    const gtmData = {
        event: "addtocart",
        page,
        ecommerce: {
            content_id: product.sku,
            content_type: "product",
            content_name: product.title,
            quantity: product.quantity,
            price: product.price,
            currency: "USD",
            add: {
                products: [
                    {
                        name: product.title, // Name or ID is required.
                        id: product.sku,
                        price: product.price,
                        brand: product.brand,
                        category: product.category,
                        variant: "",
                        quantity: product.quantity,
                    },
                ],
            },
        },
    };
    const calls = [];
    calls.push(
        axios.get("store/fb_add_to_cart", {
            params: {...product, current_path: page.path},
        })
    );
    calls.push(getCartStage());
    const [res, cart_stage] = await Promise.all(calls);
    const eventId = "AddToCart-" + res.headers["x-request-id"];
    window.dataLayer?.push({ecommerce: null});
    window.dataLayer?.push({
        ...gtmData,
        fbEventID: eventId,
        cart_stage,
    });

    window.analytics?.track(
        "Product Added",
        {
            affiliate: store.state.cart.cart.affiliate,
            cart_stage,
            value: product.price, // ENG-1112 - Adding tracking for segment pixel usage
            timestamp: dateTimeInSeconds(),
            ...eventProductModel,
        },
        getSegmentTrackingContextData(eventId)
    );
});

document.addEventListener("_sf_remove_from_cart", () => {
    //TODO: Add Event Handler
});

/* Select Payment Method Event */
document.addEventListener("_sf_payment_type", async () => {
    const cart = store.state.cart.cart;
    const products = getProducts();

    const gtmData = {
        event: "selected_payment_type",
        ecommerce: {
            currency: "USD",
            coupon: cart.coupon?.code || null,
            value: cart.total / 100,
            checkout: {
                actionField: {step: 2, option: cart.payment_type},
                products,
            },
        },
    };

    window.dataLayer?.push({ecommerce: null});
    window.dataLayer?.push(gtmData);
    const cart_stage = await getCartStage();

    window.analytics?.track(
        "Checkout Step Completed",
        {
            affiliate: store.state.cart.cart.affiliate,
            cart_stage,
            checkout_step_name: "payment",
            checkout_step_number: 3,
            value: cart.total / 100,
            timestamp: dateTimeInSeconds()
        },
        getSegmentTrackingContextData(null, "Checkout Step Completed")
    );
});

/* Add to cart - all products  */
/* Trigger: Complete purchase, payment successful + order created in db, order confirmation */
document.addEventListener("_sf_purchase", async (e) => {
    const cart = store.state.cart.cart;
    const products = getProducts();
    const purchased_song_revision_product = cart.hasOriginalSongRevision;

    const song_purchase_event_id = "SongPurchased-" + e.detail.order_id;
    const order_fb_event_id = "Purchase-" + e.detail.order_id;
    const order_event_id = "OrderCompleted-" + e.detail.order_id;

    if (purchased_song_revision_product) {
        window.analytics?.track(
            "Song Revision Completed",
            {
                affiliate: store.state.cart.cart.affiliate,
                story_id: purchased_song_revision_product.parent_story_id,
                song_revisions:
                    purchased_song_revision_product.extra_info.revision_requests.map(
                        (rev) => {
                            const lineRef =
                                rev.type === "update"
                                    ? rev.edits?.[0].line
                                    : rev.line;
                            return {
                                revision_type: rev.type,
                                revision_lyric_reference: "Line " + lineRef,
                            };
                        }
                    ),
                quiz_name: "revisions",
            },
            getSegmentTrackingContextData(
                purchased_song_revision_product.parent_story_id,
                "SongRevisionCompleted"
            )
        );
    }

    const discountTotal = cart.discount;

    // Can only purchase one song at the moment
    const purchased_song_product = cart.hasOriginalSong || cart.hasInstantSong;

    // Segment
    const segmentOrderData = {
        song_included: !!purchased_song_product,
        checkout_id: "T" + e.detail.order_id,
        coupon: cart.coupon?.code || null,
        currency: "USD",
        credit_applied: cart.credit_applied,
        discount: discountTotal ? discountTotal / 100 : 0,
        order_id: e.detail.order_id,
        payment_method: e.detail.payment_type,
        shipping: cart.shipping / 100,
        subtotal: cart.subtotal / 100,
        tax: cart.taxes / 100,
        total: cart.total / 100,
        value: cart.total / 100, // ENG-1112 - Adding tracking for segment pixel usage
        fbEventID: order_fb_event_id,
        products,
    };

    if (!products.length) return;
    //Map products in response object from API to product event product model
    const nonSongReportedProducts = e.detail.non_song_product_data?.map(
        mapBackendProductToEventProductModel
    ) || [];
    const user = store.state.auth.user || getSessionUser();

    const gtmData = {
        event: "purchase",
        page: {
            path: "/checkout",
            pageTitle: "Checkout",
        },
        ecommerce: {
            contents: products,
            value: cart.total / 100,
            coupon: cart.coupon?.code || null,
            coupon_total: discountTotal ? discountTotal / 100 : null,
            currency: "USD",
            subtotal: cart.subtotal / 100,
            credit_applied: cart.credit_applied,
            user: {
                email: user.email,
                first_name: user.first_name,
                last_name: user.last_name,
            },
            transactionID: e.detail.order_id,
            chargeID: e.detail.charge_id,
            purchase: {
                actionField: {
                    id: "T" + e.detail.order_id, // Transaction ID. Required for purchases and refunds.
                    affiliation: "Songfinch Store",
                    revenue: cart.total / 100, // Total transaction value (incl. tax and shipping)
                    tax: cart.taxes / 100,
                    shipping: cart.shipping / 100,
                    coupon: cart.coupon?.code || null,
                    currency: "USD",
                },
                products,
            },
        },
        fbEventID: order_fb_event_id,
    };

    if (cart.addresses.shipping) {
        gtmData.home_address = {
            street:
                cart.addresses.shipping.line1 +
                " " +
                (cart.addresses.shipping.line2 || ""),
            city: cart.addresses.shipping.city,
            region: cart.addresses.shipping.state,
            postal_code: cart.addresses.shipping.postal_code,
            country: cart.addresses.shipping.country,
        };
    }

    const songProducts = products.filter(p => p.instant_product || p.code === "personalized-song");

    const segmentSongsData = songProducts.map((songProduct) => {
        const song_type = songProduct.instant_product ? "InstantProduct": "Song";
        const baseSegmentSongData = {
            // Order Ecomm Attributes
            billing_address_city: cart.addresses.billing?.city,
            billing_address_country_code: cart.addresses.billing?.country_code,
            billing_address_postal_code: cart.addresses.billing?.postal_code,
            billing_address_state_code: cart.addresses.billing?.state_code,
            checkout_id: "T" + e.detail.order_id,
            coupon: cart.coupon?.code || null,
            currency: "USD",
            credit_applied: cart.credit_applied,
            discount: discountTotal ? discountTotal / 100 : 0,
            order_id: e.detail.order_id,
            payment_method: e.detail.payment_type,
            shipping: cart.shipping / 100,
            subtotal: cart.subtotal / 100,
            tax: cart.taxes / 100,
            total: cart.total / 100,
            value: cart.total / 100, // ENG-1112 - Adding tracking for segment pixel usage
            products,
            // Song Ecomm Attributes
            story_id: e.detail.slug,
            price: songProduct.price,
            core_product_type: song_type,
            add_on: false,
        };

        //Refresh GTM songbuilder data from each step
        initGtmData();

        if (songProduct.instant_product) {
            return baseSegmentSongData;
        }

        return {
            ...baseSegmentSongData,
            // SongBuilder Attributes
            ...omit(
                mapAndEnrichSongbuilderDataForSegment(store.state.songBuilder),
                ["step_number", "step_name"]
            ),
        };
    });

    //Enrich the product event model w/ order info to associate w/ the order/story
    const segmentItemData = nonSongReportedProducts.map((p) => {
        return {
            order_id: e.detail.order_id,
            checkout_id: "T" + e.detail.order_id,
            story_id: e.detail.slug,
            coupon: cart.coupon?.code || null,
            ...p,
        };
    });

    const cart_stage = await getCartStage();

    window.dataLayer?.push({ecommerce: null});
    window.dataLayer?.push({
        ...gtmData,
        cart_stage,
    });

    window.analytics?.track(
        "Order Completed",
        {
            affiliate: store.state.cart.cart.affiliate,
            cart_stage,
            timestamp: dateTimeInSeconds(),
            ...segmentOrderData,
        },
        getSegmentTrackingContextData(order_event_id)
    );

    segmentSongsData.forEach((segmentSongData) => {
        window.dataLayer?.push({ecommerce: null});
        window.dataLayer?.push({
            event: "song_purchase",
            page: gtmData.page,
            ecommerce: gtmData.ecommerce,
            segment: {
                ...segmentSongData,
                cart_stage,
            },
            fbEventID: song_purchase_event_id,
        });
        window.analytics?.track(
            "Song Purchased",
            {
                affiliate: store.state.cart.cart.affiliate,
                cart_stage,
                ...segmentSongData,
            },
            getSegmentTrackingContextData(song_purchase_event_id)
        );
    });

    //Only report purchased products
    segmentItemData.forEach((p) => {
        const item_purchase_event_id = `ItemPurchased-${e.detail.order_id}-${p.id}`;
        window.analytics?.track(
            "Item Purchased",
            {
                affiliate: store.state.cart.cart.affiliate,
                cart_stage,
                value: p.price, // ENG-1112 - Adding tracking for segment pixel usage
                timestamp: dateTimeInSeconds(),
                ...p,
            },
            getSegmentTrackingContextData(item_purchase_event_id)
        );
    });
});

//View Event Reminder Or Artist Application Form
document.addEventListener("_sf_viewform", () => {
    window.dataLayer?.push({
        event: "viewform",
        page: {
            path: location.pathname,
            pageTitle: document.title,
        },
    });
    // window.analytics?.track("viewform", {
    //     page: {
    //         path: location.pathname,
    //         pageTitle: document.title,
    //     }
    // });
});

//Start Checkout
document.addEventListener("_sf_startcheckout", async () => {
    const products = getProducts();
    const cart = store.state.cart.cart;
    const gtmData = {
        event: "startcheckout",
        page: {
            path: location.pathname,
            pageTitle: "Checkout",
        },
        ecommerce: {
            subtotal: cart.subtotal / 100,
            value: cart.total / 100,
            credit_applied: cart.credit_applied / 100,
            products,
        },
    };
    const segmentData = {
        subtotal: cart.subtotal / 100,
        total: cart.total / 100,
        value: cart.total / 100, // ENG-1112 - Adding tracking for segment pixel usage
        credit_applied: cart.credit_applied / 100,
        currency: "USD",
        products,
    };
    const cart_stage = await getCartStage();
    window.dataLayer?.push({
        ...gtmData,
        cart_stage,
    });
    window.analytics?.track(
        "Checkout Started",
        {
            affiliate: store.state.cart.cart.affiliate,
            cart_stage,
            timestamp: dateTimeInSeconds(),
            ...segmentData,
        },
        getSegmentTrackingContextData(null, "Checkout Started")
    );
});

//Forms Submission
document.addEventListener("_sf_submitform", (e) => {
    const lead_form_event_id = `LeadFormCompleted-${e.detail.request_id}`;
    window.dataLayer?.push({
        event: "submitform",
        page: {
            path: location.pathname,
            pageTitle: document.title,
        },
        form: {
            ...e.detail.form,
        },
        formName: e.detail.formName,
        request_id: e.detail.request_id,
    });
    const user_email = e.detail.form.email;
    window.analytics?.track(
        "Lead Form Completed",
        {
            email: user_email,
            form_type: e.detail.formName,
            request_id: e.detail.request_id,
            // quiz_name: SONG_BUILDER_QUIZ_NAME,
            // quiz_version: SONG_BUILDER_QUIZ_NUMBER,
        },
        getSegmentTrackingContextData(lead_form_event_id)
    );
});

document.addEventListener("_sf_identify_user", (e) => {
    if (document.cookie.indexOf("ajs_user_id=") === -1) {
        window.analytics?.identify({
            email: e.detail.email,
            timezone: Intl?.DateTimeFormat().resolvedOptions().timeZone,
            features: flipperValues
        }, getSegmentTrackingContextData(null, null));
    }
});

//Song Builder Forms Submissions
document.addEventListener("_sf_songbuildersubmit", (e) => {
    const step = e.detail?.step || router.currentRoute.value.name;

    gtm_song_data_helper[step]?.();
    window.dataLayer?.push({
        event: e.detail?.event || "songbuildersubmit",
        page: router.currentRoute.value.name,
        ...store.state.songBuilder.gtmSongData,
    });
    const segmentData = mapAndEnrichSongbuilderDataForSegment(
        store.state.songBuilder
    );
    //Filter out erroneous SB complete events we are publishing at the wrong time. I think this is due to not having
    // Handlers for certain events. We still want to filter these out but if this doesn't work we can add specific
    // empty handlers for each step
    // This occurs w/ BsStoryMustIncludesIntro

    if (gtm_song_data_helper[step]) {
        window.analytics?.track(
            "Songbuilder Step Completed",
            {
                affiliate: store.state.cart.cart.affiliate,
                song_customer_category: "lead",
                quiz_name: SONG_BUILDER_QUIZ_NAME,
                quiz_version: SONG_BUILDER_QUIZ_NUMBER,
                timestamp: dateTimeInSeconds(),
                ...segmentData,
            },
            getSegmentTrackingContextData(null, "Songbuilder Step Completed")
        );
    }
});

//Song Builder Review Submission
document.addEventListener("_sf_songbuildersumit_review", (e) => {
    gtm_song_data_helper[router.currentRoute.value.name]?.();
    //Legacy behavior for GTM
    window.dataLayer?.push({
        event: e.detail?.event || "songbuildersubmit",
        page: router.currentRoute.value.name,
        ...store.state.songBuilder.gtmSongData,
    });
    const segmentData = mapAndEnrichSongbuilderDataForSegment(
        store.state.songBuilder
    );
    window.analytics?.track(
        "Songbuilder Completed",
        {
            affiliate: store.state.cart.cart.affiliate,
            song_customer_category: "lead",
            quiz_name: SONG_BUILDER_QUIZ_NAME,
            quiz_version: SONG_BUILDER_QUIZ_NUMBER,
            ...segmentData,
        },
        getSegmentTrackingContextData(null, "Songbuilder Completed")
    );
});

//Artist view list page after song builder
document.addEventListener("_sf_songbuilder_recommended_artist_list", (e) => {
    const recommended_list = map(e.detail, (el) => el.artist_name).join(", ");
    //Turn into list, letting marketing determine which style they want
    const artist_list = map(e.detail, (el) => el);
    const gtmData = {
        event: "bs_recommended_artist_list",
        recommended_list,
    };
    window.dataLayer?.push(gtmData);
    window.analytics?.track(
        "Songbuilder Artist Selection Received",
        {
            affiliate: store.state.cart.cart.affiliate,
            artist_list,
            recommended_list,
            quiz_name: SONG_BUILDER_QUIZ_NAME,
            quiz_version: SONG_BUILDER_QUIZ_NUMBER,
            timestamp: dateTimeInSeconds()
        },
        getSegmentTrackingContextData(
            null,
            "Songbuilder Artist Selection Received"
        )
    );
});

//Artist view list page after song builder
// document.addEventListener('_sf_search_artist_from_bs', (e) => {
//     window.dataLayer?.push({
//         event: 'search_artist_from_bs',
//     });
//     // if(window.analytics) {
//     //     analytics.track('search_artist_from_bs');
//     // }
// })

//Apply Coupon
document.addEventListener("_sf_apply_coupon", () => {
    if (!store.state.cart.cart.coupon) return;
    window.dataLayer?.push({
        event: "apply_coupon",
        ecommerce: {
            coupon: store.state.cart.cart.coupon.code,
        },
    });
    const discountTotal = store.state.cart.cart.discount;
    window.analytics?.track(
        "Checkout Apply Coupon",
        {
            affiliate: store.state.cart.cart.affiliate,
            coupon: store.state.cart.cart.coupon?.code,
            coupon_total: discountTotal ? discountTotal / 100 : null,
        },
        getSegmentTrackingContextData(
            store.state.cart.cart.coupon.code,
            "Checkout Apply Coupon"
        )
    );
});

//Start song with artist
document.addEventListener("_sf_start_song_with_artist", (e) => {
    const artist = e.detail.artist;
    window.dataLayer?.push({
        event: "start_song_with_artist",
        artist_id: artist.id,
        artist_name: artist.artist_name,
    });
    window.analytics?.track(
        "CTA Clicked",
        {
            destination: e.detail.destination,
            location: e.detail.location,
            text: e.detail.text,
            type: e.detail.type,
            artist_id: artist.id,
            artist_name: artist.artist_name,
        },
        getSegmentTrackingContextData(null, "CTA Clicked")
    );
});

//Link Clicked
document.addEventListener("_sf_cta_clicked", (e) => {
    window.analytics?.track(
        "CTA Clicked",
        {timestamp: dateTimeInSeconds(), ...e?.detail},
        getSegmentTrackingContextData(null, "CTA Clicked")
    );
});

//user login event
document.addEventListener("_sf_user_login", (e) => {
    const user = store.state.auth.user;
    const user_id = getUserIdentifierFromStateStoreOrCookie(user);
    window.dataLayer?.push({
        event: "UserInit",
        user_id: user_id,
        user_email: user.email,
        user_first_name: user.first_name,
        user_last_name: user.last_name,
        user_role: user.role,
    });

    if (store.state.auth.redirectQuery?.push_to === "Checkout") {
        const data = e.detail.registration
            ? {
                ga_action: "User created an account from /checkout",
                ga_tag: "CreateAccountCheckout",
            }
            : {
                ga_action: "User logged in from /checkout",
                ga_tag: "LoginCheckout",
            };
        app.config.globalProperties.$customEvent("_sf_custom_ga_event", data);
    }

    if (e.detail.registration) {
        window.dataLayer?.push({
            event: "signup",
            page: {
                path: location.pathname,
                pageTitle: document.title,
            },
            user,
        });

        window.analytics?.track(
            "Account Created",
            {
                email: user.email,
                firstName: user.first_name,
                lastName: user.last_name,
                referral_source: user.heard_about_from + (user.heard_about_from_other ? ` - ${user.heard_about_from_other}` : ""),
                referral_code: store.state.auth.referralCode || "",
            },
            getSegmentTrackingContextData(user_id, "Account Created")
        );
    } else {
        window.analytics?.track(
            "Signed In",
            {
                email: user.email,
            },
            getSegmentTrackingContextData(null, "Signed In")
        );
    }

    if (store.state.auth.referralCode && e.detail.registration) {
        app.config.globalProperties.$customEvent("_sf_custom_ga_event", {
            ga_action:
                "Referred user creates an account from the unique referral landing page",
            ga_tag: "ReferralSignup",
        });
    }

    window.analytics?.identify(user?.external_user_id, {
        email: user.email,
        firstName: user.first_name,
        lastName: user.last_name,
        guest: user.guest,
        role: user.role || null,
        timezone: Intl?.DateTimeFormat().resolvedOptions().timeZone,
        features: flipperValues
    }, getSegmentTrackingContextData(null, null));
});

//user logout event
document.addEventListener("_sf_user_logout", () => {
    const user = store.state.auth.user;
    window.dataLayer?.push({
        event: "user_logout",
    });
    window.analytics?.track(
        "Signed Out",
        {
            email: user.email,
        },
        getSegmentTrackingContextData(null, "Signed Out")
    );
});

//TODO: redo events above
//Custom Event
//e.detail should contain {ga_category: "", ga_action: "", ga_tag: "", ga_label:""} label - optional
document.addEventListener("_sf_custom_ga_event", async (e) => {
    window.dataLayer?.push({
        event: "custom_ga_event",
        ...e.detail,
    });

    let segmentData = {};
    let trackingContext = {};
    let baseSegmentData = {};
    let cart_stage;
    const cart = store.state?.cart?.cart;
    const story = e.detail?.story;

    switch (e.detail.ga_tag) {
    case "SubmitAccount":
        segmentData = {
            checkout_step_name: "account",
            checkout_step_number: 0,
            value: (cart?.total || 0) / 100, // ENG-1112 - Adding tracking for segment pixel usage
        };
        cart_stage = await getCartStage();
        window.analytics?.track(
            "Checkout Step Completed",
            {
                affiliate: store.state.cart.cart.affiliate,
                cart_stage,
                timestamp: dateTimeInSeconds(),
                ...segmentData,
            },
            getSegmentTrackingContextData(null, "Checkout Step Completed")
        );
        break;
    case "SubmitBilling":
        segmentData = {
            checkout_step_name: "billing_address",
            checkout_step_number: 1,
            value: (cart?.total || 0) / 100, // ENG-1112 - Adding tracking for segment pixel usage
        };
        cart_stage = await getCartStage();
        window.analytics?.track(
            "Checkout Step Completed",
            {
                affiliate: store.state.cart.cart.affiliate,
                cart_stage,
                timestamp: dateTimeInSeconds(),
                ...segmentData,
            },
            getSegmentTrackingContextData(null, "Checkout Step Completed")
        );
        break;
    case "SubmitShipping":
        segmentData = {
            checkout_step_name: "shipping_address",
            checkout_step_number: 2,
            value: (cart?.total || 0) / 100, // ENG-1112 - Adding tracking for segment pixel usage
        };
        cart_stage = await getCartStage();
        window.analytics?.track(
            "Checkout Step Completed",
            {
                affiliate: store.state.cart.cart.affiliate,
                cart_stage,
                timestamp: dateTimeInSeconds(),
                ...segmentData,
            },
            getSegmentTrackingContextData(null, "Checkout Step Completed")
        );
        break;
    case "DownloadSong":
        baseSegmentData = {
            email: e.detail.email,
            story_id: story.slug,
            song_genre: story.genre,
            core_product_type: story.song ? "Song" : "instantProduct"
        };

        if (story.song) {
            const song = story.song;
            const songData = {
                song_title: song.title,
                song_artist: song.artist.fullName,
                song_artist_id: song.artist.id,
            };
            segmentData = {...baseSegmentData, ...songData};
        } else {
            const instant_product = story.instant_product;
            const instantProductData = {
                song_title: instant_product.title,
                song_artist: instant_product.artist_display_name,
                song_artist_id: instant_product.artist?.id,
            };
            segmentData = {...baseSegmentData, ...instantProductData};
        }

        window.analytics?.track(
            "Song Downloaded",
            segmentData,
            getSegmentTrackingContextData(null, "Song Downloaded")
        );
        break;
    case "SongPageListened":
        segmentData = {
            email: e.detail.email,
            story_id: e.detail.story.story_id,
            story_slug: e.detail.story.slug,
            song_genre: e.detail.story.song.genre,
            song_title: e.detail.story.song.title,
            song_artist: e.detail.story.song.artist.fullName,
            song_artist_id: e.detail.story.song.artist.id
        };
        trackingContext = getSegmentTrackingContextData(null, "Song Page Listened");
        trackingContext.traits.email = e.detail.email; // using email from event to identify user
        window.analytics?.track(
            "Song Page Listened",
            segmentData,
            trackingContext
        );
        break;
    case "SongPageVIPGiveaway":
        segmentData = {
            email: e.detail.email,
            story_id: e.detail.story.story_id,
            story_slug: e.detail.story.slug,
            song_genre: e.detail.story.song.genre,
            song_title: e.detail.story.song.title,
            song_artist: e.detail.story.song.artist.fullName,
            song_artist_id: e.detail.story.song.artist.id
        };
        trackingContext = getSegmentTrackingContextData(null, "Song Page VIP giveaway");
        trackingContext.traits.email = e.detail.email;
        window.analytics?.track(
            "Song Page VIP giveaway",
            segmentData,
            trackingContext
        );
        break;
    case "InstantSongPreviewLead":
        segmentData = {
            email: e.detail.email,
        };
        trackingContext = getSegmentTrackingContextData(null, "Instant Song Preview Lead");
        trackingContext.traits.email = e.detail.email;
        window.analytics?.track(
            "Instant Song Preview Lead",
            segmentData,
            trackingContext
        );
        break;
    }
});

document.addEventListener("_sf_song_page_viewed", (e) => {
    const story = e.detail.story;
    const baseSegmentData = {
        story_id: story.slug,
        song_genre: story.genre,
        core_product_type: story.song ? "Song" : "InstantProduct"
    };
    let segmentData;

    if (story.song) {
        const song = story.song;
        const songData = {
            song_title: song.title,
            song_artist: song.artist.fullName,
            song_artist_id: song.artist.id,
            song_link: song.audioUrl,
            is_gifter: story.is_owner
        };
        segmentData = {...baseSegmentData, ...songData};
    } else {
        const instant_product = story.instant_product;
        const instantProductData = {
            song_title: instant_product.title,
            song_artist: instant_product.artist_display_name,
            song_artist_id: instant_product.artist?.id,
            song_link: instant_product.audioUrl,
            is_gifter: story.is_owner
        };
        segmentData = {...baseSegmentData, ...instantProductData};
    }

    window.analytics?.track(
        "Song Page Viewed",
        segmentData,
        getSegmentTrackingContextData(null, "Song Page Viewed")
    );
});

document.addEventListener("_sf_song_started", (e) => {
    const song = e.detail.song;
    const player = e.detail.player;
    const segmentData = {
        story_id: song.slug,
        song_title: song.song_title,
        song_artist: song.name,
        song_artist_id: song.song_artist_id,
        song_genres: song.genres,
        song_occasions: song.occasions,
        song_duration: player.duration,
        song_duration_sec: player.maxRange,
        media_type: song.media_type,
        player_type: song.player_type,
    };
    window.analytics?.track(
        "Song Started",
        segmentData,
        getSegmentTrackingContextData(null, "Song Started")
    );
});

document.addEventListener("_sf_song_listened", (e) => {
    const song = e.detail.song;
    const player = e.detail.player;
    const segmentData = {
        story_id: song.slug,
        song_title: song.song_title,
        song_artist: song.name,
        song_artist_id: song.song_artist_id,
        song_genres: song.genres,
        song_occasions: song.occasions,
        song_duration: player.duration,
        song_duration_sec: player.maxRange,
        elapsed_time: player.currentTime,
        elapsed_time_sec: player.currentRange,
        elapsed_percentage: Math.min(
            Math.floor((player.currentRange / player.maxRange) * 100),
            100
        ),
        media_type: song.media_type,
        player_type: song.player_type,
    };
    window.analytics?.track(
        "Song Listened",
        segmentData,
        getSegmentTrackingContextData(null, "Song Listened")
    );
});

document.addEventListener("_sf_checkout_sign_in_viewed", () => {
    window.analytics?.track(
        "Checkout Sign In Viewed",
        {},
        getSegmentTrackingContextData(null, "Checkout Sign In Viewed")
    );
});

document.addEventListener("_sf_expired_magic_link_viewed", () => {
    window.analytics?.track(
        "Expired Magic Link Viewed",
        {},
        getSegmentTrackingContextData(null, "Expired Magic Link Viewed")
    );
});

document.addEventListener("_sf_account_email_viewed", () => {
    window.analytics?.track(
        "Account Email Viewed",
        {},
        getSegmentTrackingContextData(null, "Account Email Viewed")
    );
});


document.addEventListener("_sf_get_started_slide_viewed", e => {
    window.analytics?.track(
        "Get Started Mobile Slide Viewed",
        e.detail,
        getSegmentTrackingContextData(null, "Get Started Mobile Slide Viewed")
    );
});

document.addEventListener("_sf_song_info_viewed", e => {
    window.analytics?.track(
        "Song Information Viewed",
        e.detail,
        getSegmentTrackingContextData(null, "Song Info Viewed")
    );
});

document.addEventListener("_sf_song_shared", e => {
    window.analytics?.track(
        "Song Shared",
        e.detail,
        getSegmentTrackingContextData(null, "Song Shared")
    );
});

document.addEventListener("_sf_email_triggered", e => {
    window.analytics?.track(
        "Email Triggered",
        e.detail,
        getSegmentTrackingContextData(null, "Email Triggered")
    );
});

document.addEventListener("_sf_song_conversion", e => {
    window.analytics?.track(
        "Song Conversion",
        e.detail,
        getSegmentTrackingContextData(null, "Song Conversion")
    );
});

document.addEventListener("_sf_song_engage", e => {
    window.analytics?.track(
        "Song Engage",
        e.detail,
        getSegmentTrackingContextData(null, "Song Engage")
    );
});

document.addEventListener("_sf_footer_conversion", () => {
    window.analytics?.track(
        "Footer Conversion",
        {},
        getSegmentTrackingContextData(null, "Footer Conversion")
    );
});

document.addEventListener("_sf_segment_event", e => {
    let baseProps = {};
    const generalProps = {timestamp: dateTimeInSeconds()};
    const quizProps = {
        quiz_name: SONG_BUILDER_QUIZ_NAME,
        quiz_version: SONG_BUILDER_QUIZ_NUMBER,
    };

    if (e?.detail?.props?.includeQuizInfo) {
        baseProps = {...generalProps, ...quizProps};
        delete e.detail.props.includeQuizInfo;
    }

    window.analytics?.track(
        e.detail.name,
        {...baseProps, ...e?.detail?.props},
        getSegmentTrackingContextData(null, e.detail.name)
    );
});

//Instant EVENTS
document.addEventListener("_sf_ISBStepLoad", e => {
    window.analytics?.track(
        "ISBQuizQuestion",
        {
            ...e.detail,
            template: vitamixCollectionTitle.value,
            affiliate: store.state.cart.cart.affiliate,
            timestamp: dateTimeInSeconds(),
        },
        getSegmentTrackingContextData(null, "ISBQuizQuestion")
    );
});

document.addEventListener("_sf_ISBPreviewLoad", e => {
    window.analytics?.track(
        "ISBSongPreview",
        {
            ...e.detail,
            template: vitamixCollectionTitle.value,
            affiliate: store.state.cart.cart.affiliate,
            timestamp: dateTimeInSeconds(),
        },
        getSegmentTrackingContextData(null, "ISBSongPreview")
    );
});

document.addEventListener("_sf_ISBPreviousSongsLoad", () => {
    window.analytics?.track(
        "ISBPreviousSongs",
        {
            template: vitamixCollectionTitle.value,
            affiliate: store.state.cart.cart.affiliate,
            timestamp: dateTimeInSeconds(),
        },
        getSegmentTrackingContextData(null, "ISBPreviousSongs")
    );
});

document.addEventListener("_sf_ISBQuizAnswerSelected", e => {
    const data = e.detail;
    const isMultiple = data.questionKeys.length > 1;
    const eventData = {
        question: e.detail.currentQuestion.question,
        type: isMultiple ? "multi-select" : "select",
        step: e.detail.step,
        template: vitamixCollectionTitle.value
    };
    if (isMultiple) eventData.answers = [];
    data.questionKeys.forEach((k, i) => {
        const indexKey = i ? (i + 1) : "";
        const answer = data.answers[k] || null;
        eventData[`answer${indexKey}`] = e.detail.currentQuestion.answers.find(el => el.answer_key === answer)?.answer;
        eventData[`previous_answer${indexKey}`] = e.detail.currentQuestion.answers.find(el => el.answer_key === data.previousAnswers[k])?.answer || null;
        if (isMultiple) eventData.answers.push(answer);
    });

    window.analytics?.track(
        "ISBQuizAnswerSelected",
        eventData,
        getSegmentTrackingContextData(null, "ISBQuizAnswerSelected")
    );
});

document.addEventListener("_sf_ISBPreviewEvents", e => {
    const eventData = {
        is_playing_preview: app.config.globalProperties.$sfPlayer.isPlaying,
        template: vitamixCollectionTitle.value,
        ...e.detail,
        timestamp: dateTimeInSeconds(),
    };
    const eventName = eventData.event;
    delete eventData.event;
    window.analytics?.track(
        eventName,
        eventData,
        getSegmentTrackingContextData(null, "eventData")
    );
});

document.addEventListener("_sf_ISBPreviousSongsClose", () => {
    window.analytics?.track(
        "ISBPreviousSongsBack",
        {
            template: vitamixCollectionTitle.value,
            timestamp: dateTimeInSeconds(),
            is_playing_preview: app.config.globalProperties.$sfPlayer.isPlaying,
        },
        getSegmentTrackingContextData(null, "ISBPreviousSongsBack")
    );
});
