import {setMetaToAllChildren} from "../helpers/routes_helper";
import router from "./index";
import store from "../store";
import {setLastReferralPage} from "@songfinch/customer/composables/useReferralPage";

const SongBuilder = () => import("@songfinch/customer/views/build_song/SongBuilder");

const BsOccasionRecipient = () => import("@songfinch/customer/views/build_song/chapters/pre_purchase/BsOccasionRecipient");
const BsGifterEmail = () => import("@songfinch/customer/views/build_song/chapters/pre_purchase/BsGifterEmail");
const BsSongArtist = () => import("@songfinch/customer/views/build_song/chapters/pre_purchase/BsSongArtist");
const BsSongOptions = () => import("@songfinch/customer/views/build_song/chapters/pre_purchase/BsSongOptions");
const BsSongArtistSelection = () => import("@songfinch/customer/views/build_song/chapters/pre_purchase/BsSongArtistSelection");
const BsStoryDetailsCombined = () => import("@songfinch/customer/views/build_song/chapters/pre_purchase/BsStoryDetailsCombined");
const BsArtistsRecommendation = () => import("@songfinch/customer/views/build_song/chapters/pre_purchase/BsArtistsRecommendation");
const BsArtistConfirmation = () => import("@songfinch/customer/views/build_song/chapters/pre_purchase/BsArtistConfirmation");
const BsReview = () => import("@songfinch/customer/views/build_song/chapters/pre_purchase/BsReview");
const BsPreCart = () => import("@songfinch/customer/views/build_song/chapters/pre_purchase/BsPreCart");

function skipSongBuilderRoute(to, from, next, skip) {
    if (skip) {
        const isPrevPage = from.meta.index === undefined || from.meta.index > to.meta.index;
        const nextPage = {name: isPrevPage ? to.meta.prevPage : to.meta.nextPage, params: to.params, query: to.query};
        store.commit("songBuilder/setLastSongBuildPage", {name: nextPage.name, params: nextPage.params});
        return next(nextPage);
    }
    next();
}

function bsPageRedirectionGuard(to, from, next) {
    const isLastBuilderPage = from.matched[0]?.name === "SongBuilder";
    const lastPage = store.state.songBuilder.songData.lastSongBuildPage;
    if (!isLastBuilderPage && lastPage?.name && lastPage.name !== to.name) {
        try {
            lastPage.query = {...lastPage.query, ...to.query};
            router.resolve(lastPage);
            return next(lastPage);
        } catch (e) {
            store.commit("songBuilder/setLastSongBuildPage", null);
            return next({name: "SongBuilder"});
        }
    }
    return next();
}

const songBuilderRoutes = {
    path: "/song-builder",
    alias: "/build-your-song",
    name: "SongBuilder",
    component: SongBuilder,
    redirect: {name: "BsOccasionRecipient"},
    meta: {
        CMSData: "songBuilderPrePurchase",
        animation: "pageSlide",
        hideTopBar: true,
        hideNavgiation: true,
        hideFooter: true,
        hideStartButton: true,
        defaultReferralPage: "Home"
    },
    beforeEnter: [setLastReferralPage],
    children: [
        {
            path: "recipient",
            name: "BsOccasionRecipient",
            component: BsOccasionRecipient,
            beforeEnter: [bsPageRedirectionGuard]
        },
        {
            path: "artist",
            name: "BsSongArtist",
            component: BsSongArtist,
            beforeEnter(to, from, next) {
                const skip = store.state.songBuilder.songData.selected_artists?.find(a => a.fromSinglePage);
                skipSongBuilderRoute(to, from, next, skip);
            }
        },
        {
            path: "artist-selection",
            name: "BsSongArtistSelection",
            component: BsSongArtistSelection,
            beforeEnter(to, from, next) {
                skipSongBuilderRoute(to, from, next, !store.state.songBuilder.songData.preferred_artist_switch);
            }
        },
        {
            path: "song-options",
            name: "BsSongCharacteristics",
            component: BsSongOptions,
            beforeEnter(to, from, next) {
                const userSelectedAnArtistSwitch = store.state.songBuilder.songData.preferred_artist_switch === undefined;
                skipSongBuilderRoute(to, from, next, userSelectedAnArtistSwitch);
            }
        },
        {
            path: "gifter-email",
            name: "BsGifterEmail",
            component: BsGifterEmail,
            meta: {hideSongbuilderHeader: true},
            beforeEnter(to, from, next) {
                const songData = store.state.songBuilder.songData;
                if (songData.preferred_artist_switch === undefined
                    || (!songData.selected_artists?.length && songData.preferred_artist_switch === true)
                    || store.state.auth.user
                    || songData.gifter_email
                    || !store.state.songBuilder.songData.genre
                    || !store.state.songBuilder.songData.gender
                ) {
                    return skipSongBuilderRoute(to, from, next, true);
                }
                return next();
            }
        },
        {
            path: "recommended-artists",
            name: "BsSongArtistsList",
            component: BsArtistsRecommendation,
            beforeEnter(to, from, next) {
                if (!store.state.songBuilder.songData.genre || !store.state.songBuilder.songData.gender) {
                    return skipSongBuilderRoute(to, from, next, true);
                }
                skipSongBuilderRoute(to, from, next, store.state.songBuilder.songData.preferred_artist_switch);
            }
        },
        {
            path: "artist-confirmation",
            name: "BsArtistConfirmation",
            component: BsArtistConfirmation,
            beforeEnter(to, from, next) {
                const songData = store.state.songBuilder.songData;
                if (!(songData.selected_artists?.length || songData.selected_recommended_artist || songData.pick_for_me)) {
                    return skipSongBuilderRoute(to, from, next, true);
                }
                return next();
            }
        },
        {
            path: "details",
            name: "BsStoryDetailsCombined",
            component: BsStoryDetailsCombined
        },
        {
            path: "review",
            name: "BsReview",
            component: BsReview,
            meta: {hideProgressNav: true},
            beforeEnter: async () => !!await store.dispatch("songBuilder/validateSongData", {toPage: "BsReview"})
        },
        {
            path: "pre-cart",
            name: "BsPreCart",
            component: BsPreCart,
            meta: {hideProgressNav: true, customPageViewedAnalyticsTrigger: true},
            beforeEnter(to, from, next) {
                //Song should be in cart after BsReview step
                if (!store.state.cart.cart.hasOriginalSong) return next({name: "BsReview"});
                next();
            }
        }
    ]
};


setMetaToAllChildren(
    songBuilderRoutes,
    {CMSData: "songBuilderPrePurchase"}
);

export default [songBuilderRoutes];
