import axios from "axios";
import {apply_query_filters, get_query_filters} from "../helpers/apply_query_filters";
import router from "../router";

const CancelToken = axios.CancelToken;
let source;

export default {
    namespaced: true,
    state: {
        artistsList: null,
        filters: null,
        page: 1,
        endOfPages: false,
        total: false,
    },
    mutations: {
        nextPage(state) {
            state.page++;
        },
        setEndOfPages(state, val) {
            state.endOfPages = val;
        },
        setArtistsList(state, val) {
            if (val?.artists) {
                const list = val.artists.map( a => ({
                    image: a.photo,
                    name: a.artist_name,
                    exclusivity: a.exclusivity,
                    audio: a.songs[0]?.audio_url,
                    link: router.resolve({name: "ArtistSingle", params: {id: a.id}}).href,
                    status: a.status,
                    genres: a.songs[0]?.genres,
                    occasions: a.songs[0]?.occasions,
                    song_subtitle: a.songs[0]?.genres.concat(a.songs[0]?.occasions, a.songs[0]?.moods).join(", "),
                    song_title: a.songs[0]?.title
                }));
                state.artistsList = state.artistsList.concat(list);
                setTimeout(() => { //Prevent to trigger it right away
                    state.total = val?.total;
                    state.endOfPages = state.artistsList.length === state.total;
                }, 100);
            }
        },
        resetList(state) {
            state.artistsList = [];
            state.page = 1;
        },
        clearLabels(state) {
            state.filters.genres = [];
            state.filters.genders = [];
            state.filters.artist_exclusivity_only = [];
        },
        initFilters(state) {
            this.commit("artist/resetList");
            state.filters = {
                genders: [],
                genres: [],
                artist_exclusivity_only: [],
                keyword: "",
                show_at_capacity: true,
                per_page: 12,
                ...get_query_filters()
            };
        }
    },
    actions: {
        async loadArtists({commit, state}, payload) {
            this.commit("artist/setEndOfPages", true);
            if (source) {
                source.cancel();
            }
            source = CancelToken.source();

            apply_query_filters(state.filters);
            let res;
            try {
                res = await axios.get("/artists", {
                    params: (payload || {page: state.page, ...state.filters}),
                    cancelToken: source.token,
                    cache: {ignoreCache: false},
                });
            } catch (e) {
                if (!axios.isCancel(e)) throw new Error(e.message || "Failed to load artist list");
                return;
            }

            if (!payload) {
                commit("setArtistsList", res.data);
            }
            return res.data;
        },
        startSongWithArtist(store, artist){
            artist.fromSinglePage = true;
            this.commit("songBuilder/selectArtist", {artist, index: 0});
            this.commit("songBuilder/selectRecommendedArtist", null);
            const lastPage = this.state.songBuilder.songData.lastSongBuildPage;
            let destination;
            if (lastPage) {
                const lastPageRoute = router.resolve(lastPage);
                destination = lastPageRoute.path;
                const artistSelectionPageRoute = router.resolve({name: "BsSongArtistSelection"});
                if (lastPageRoute.meta?.index > artistSelectionPageRoute.meta?.index) {
                    this.commit("songBuilder/setLastSongBuildPage", {name: artistSelectionPageRoute.name, params: lastPage.params});
                    destination = artistSelectionPageRoute.path;
                }
            } else {
                destination = "/song-builder";
            }
            return destination;
        }
    }
};
