import app from "@songfinch/customer/app.js";
import {createRouter, createWebHistory} from "vue-router";
import static_pages from "./pages";
import song_builder_pages from "./song_builder_pages";
import checkout_pages from "./checkout_pages";
import dashboard_pages from "./dashboard_pages";
import story_pages from "./story_pages";
import store_pages from "./store_pages";
import templates from "./templates";
import errors from "./errors";
import artists_pages from "./artists_pages";
import popups from "./popups";
import playlist_pages from "./playlist_pages";
import redirects from "./redirects";
import referrals from "./referrals";
import instant from "./instant";

import store from "../store";
import hash_scroll from "../helpers/hash_scroll";
import {nth} from "lodash-es";
import {$toastMsg} from "@songfinch/shared/plugins/toast_msg";
import {setLastPageVisited} from "@songfinch/customer/composables/useInstantProductBuilder";
import tryReload from "@songfinch/customer/helpers/tryReload";

const MAX_REDIRECTS = 10;
const redirectsList = [];
class RedirectsError extends Error {
    constructor(message, redirectsList) {
        super(message);
        this.name = this.constructor.name;
        this.redirectsList = redirectsList;
        Error.captureStackTrace(this, this.constructor);
    }
}

const routes = [
    ...redirects,
    ...static_pages,
    ...song_builder_pages,
    ...checkout_pages,
    ...dashboard_pages,
    ...story_pages,
    ...templates,
    ...store_pages,
    ...artists_pages,
    ...popups,
    ...playlist_pages,
    ...referrals,
    ...instant,
    ...errors, //should be last in list
];

function findDuplicateRouteNames(routesList, uniqueNames) {
    routesList.forEach(route => {
        if (route.name){
            if (uniqueNames[route.name]) {
                console.error("Duplicated routes:", uniqueNames[route.name], route);
                throw new Error(`Error! Route name '${route.name}' is already exist.`);
            }
            uniqueNames[route.name] = route;
            if (route.children) {
                findDuplicateRouteNames(route.children, uniqueNames);
            }
        }
    });
}

if (window.appSettings.node_env === "development") {
    findDuplicateRouteNames(routes, {});
}

const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior(to) {
        if (to.hash) return;
        return {top: 0};
    }
});


app.config.globalProperties.$bus.on(
    "pageLoaded",
    () => {
        if (!router.currentRoute.value.meta?.customPageViewedAnalyticsTrigger) {
            app.config.globalProperties.$analyticPageViewedTrigger();
        }
        hash_scroll(location.hash);
    },
    false
);

router.onError((error, to) => {
    if (error.message.startsWith("Failed to fetch dynamically imported module") || error.message.startsWith("Importing a module script failed")) {
        if (tryReload(error, "router.onError", to?.fullPath)) return true;
        return {name: "Home"};
    }
});

router.afterEach(async (to, from, failure) => {
    if (failure) return;
    //Always remove slash from the end
    history.replaceState(history.state, document.title, location.href.replace(/\/?(\?|#|$)/, "$1"));

    const cmsGroup = to.meta?.CMSData;
    await store.dispatch("cms/loadData", cmsGroup ? {group: cmsGroup} : null);

    app.config.globalProperties.$flushGTMScripts();
    setTimeout(() => app.config.globalProperties.$bus.trigger("pageLoaded"), 2); // Min transaction 1 second
});

router.beforeEach((to, from, next) => {
    if (from.name) {
        app.config.globalProperties.$bus.clearRouteEvents();
    }

    //URL Coupon on init
    if (to.query.coupon) {
        const code = Array.isArray(to.query.coupon) ? nth(to.query.coupon, -1) : to.query.coupon;
        store.commit("cart/setReservedCoupon", {code});
    }

    //Auth
    const isRequireAuth = to.matched.find(r => r.meta.requireAuth);
    if (isRequireAuth && !store.state.auth.user) {
        const redirecTo = to.name === "Checkout" ? "Signup" : "Login";
        const settings = {params: to.params, query: to.query};
        return next({name: redirecTo, query: {push_to: to.name, settings: JSON.stringify(settings)}});
    }
    if (to.redirectedFrom) {
        redirectsList.push({to, from});
        if (redirectsList.length > MAX_REDIRECTS) {
            const redirects = [...redirectsList];
            redirectsList.splice(0, redirectsList.length);
            let nextPage = "Home";
            if (to.matched[0]?.name === "SongBuilder") {
                store.commit("songBuilder/setLastSongBuildPage", null);
                nextPage = "SongBuilder";
            } else if (to.matched[0]?.name === "InstantProductBuilder") {
                setLastPageVisited("");
                nextPage = "InstantProductBuilder";
            }
            $toastMsg("Sorry, something went wrong. Please try again or contact us at support@songfinch.com.");
            setTimeout(() => {
                throw new RedirectsError(`The maximum number of redirects has been exceeded (${MAX_REDIRECTS}).`, redirects);
            });
            next({name: nextPage});
        }
    } else {
        redirectsList.splice(0, redirectsList.length);
    }
    next();
});

window.sfPushToPage = (url) => {
    return router.push(url);
};

export default router;
