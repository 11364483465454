import app from "@songfinch/customer/app.js";

/*
Put here only the stuff that has to go first
and doesn't have dependencies on other files/components.
Also stuff that gonna be used outside of vue in app init
*/

window.appSettings = window.appSettings || {};
window.appSettings.features = window.appSettings.features || {};

import EventBus from "@songfinch/shared/plugins/event_bus";
app.use(EventBus);

import sfHelpers from "@songfinch/shared/plugins/sf_helpers";
app.use(sfHelpers);

import sfAnalytics from "@songfinch/shared/plugins/sf_analytics";

app.use(sfAnalytics);

import CdnPlugin from "@songfinch/shared/plugins/cdn";
app.use(CdnPlugin);
